import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b5c82fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "w_1200" }
const _hoisted_3 = { style: {"margin-bottom":"10px"} }
const _hoisted_4 = { style: {"paddingtop":"10px,background-color:#fff,marginTop:10px"} }

import { ref } from "vue";
import BreadcrumbVue from "@/components/common/Breadcrumb/Breadcrumb.vue";

export default {
  setup(__props) {


return (_ctx, _cache) => {
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BreadcrumbVue)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}
}

}